import React from "react";
import {
  Create,
  downloadCSV,
  ShowButton,
  Edit,
  CardActions,
  SimpleForm,
  ChipField,
  ReferenceArrayInput,
  SelectArrayInput,
  TextInput,
  FileInput,
  BooleanInput,
  SelectInput,
  FileField,
  ImageInput,
  ImageField,
} from "react-admin";
import RichTextInput from "ra-input-rich-text";
import { config } from "../config";
import { createConfigItem } from "@babel/core";
import AddIcon from "@material-ui/icons/Add";
const required =
  (message = "Required") =>
  (value) =>
    value ? undefined : message;
const WorkEditActions = ({ basePath, data, resource }) => {
  console.log("config ---------- ", config().api);
  console.log(
    "data ---------- ",
    window.location.hash.replace("#/works/", "").replace("/show", "")
  );
  const token = localStorage.getItem("token");
  console.log("token ", token);
  let workId = window.location.hash
    .replace("#/works/", "")
    .replace("/show", "");
  return (
    <CardActions>
      <a
        href={config().api + "/projects/work/" + workId + "/new?token=" + token}
        style={{
          cursor: "pointer",
          color: "#2196f3",
          alignItems: "center",
          display: "flex",
          alignSelf: "center",
          textDecoration: "none",
        }}
        color="primary"
      >
        <AddIcon style={{ marginRight: 10 }}></AddIcon>
        Criar projeto
      </a>
      {/* Add your custom actions */}
      {/* <Button type={''} color="primary" onClick={customAction}>Custom Action</Button> */}
    </CardActions>
  );
};
const customAction = () => {
  window.location = "";
};
export const WorkCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput label="Name" source="name" validate={required()} />
        <TextInput label="Email" source="email" validate={required()} />
        <TextInput label="Responsable Name" source="resp_name" />
        <TextInput label="Responsable Email" source="resp_email" />
        <TextInput label="Responsable Phone" source="resp_phone" />
        <TextInput label="School Role" source="school_role" />
        <RichTextInput
          label="Description"
          source="description"
          validate={required()}
        />
        <FileInput
          source="work_zip"
          multiple={false}
          label="Work zip"
          accept="application/zip"
        >
          <FileField source="work_zip_url" title="Work zip" />
        </FileInput>
        <TextInput label="Facebook" source="link_fb" />
        <TextInput label="Instagram" source="link_insta" />
        <TextInput label="Youtube" source="link_youtube" />
        <BooleanInput label="Terms Accepted" source="terms" />
        <TextInput label="District" source="district" />
        <TextInput label="County" source="county" />
        <TextInput label="School" source="school" />
        <ReferenceArrayInput
          label="Categories"
          source="categories"
          reference="categories"
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Create>
  );
};

export const WorkEdit = (props) => (
  <Edit actions={<WorkEditActions />} {...props} title="Edit Work">
    <SimpleForm>
      <TextInput label="Name" source="name" />
      <TextInput label="Email" source="email" />
      <TextInput label="Responsable Name" source="resp_name" />
      <TextInput label="Responsable Email" source="resp_email" />
      <TextInput label="Responsable Phone" source="resp_phone" />
      <TextInput label="School Role" source="school_role" />
      <RichTextInput label="Description" source="description" />
      <TextInput label="Facebook" source="link_fb" />
      <TextInput label="Instagram" source="link_insta" />
      <TextInput label="Youtube" source="link_youtube" />
      <BooleanInput label="Grouping Project" source="terms" />
      <BooleanInput
        label="Continuation Project"
        source="continuation_project"
      />
      <BooleanInput label="Terms Accepted" source="grouping_project" />
      <TextInput label="District" source="district" />
      <TextInput label="County" source="county" />
      <TextInput label="School" source="school" />
      <TextInput label="School Id" source="school_id" />
      <ReferenceArrayInput
        label="Categories"
        source="categories"
        reference="categories"
      >
        <SelectArrayInput>
          <ChipField source="name" />
        </SelectArrayInput>
      </ReferenceArrayInput>
      <ImageField source="images" src="url" title="caption" />
      <Href label={"Work zip"} source="work_zip_url" />
    </SimpleForm>
  </Edit>
);
const Href = ({ record }) => (
  <div>
    <div
      style={{
        marginTop: "10px",
        fontSize: "16px",
        marginBottom: "5px",
        color: "rgba(0, 0, 0, 0.54)",
      }}
    >
      {" "}
      Work zip
    </div>
    <a href={config().url + "/" + record.work_zip_url} download>
      {" "}
      Download Work{" "}
    </a>
  </div>
);
