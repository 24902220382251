import React from 'react';
import { Create, Edit, SimpleForm, DisabledInput, TextInput, BooleanInput, SelectInput} from 'react-admin';

export const UserCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="email" />
            <TextInput source="password" />
            <SelectInput source="type" choices={[{id: 'admin', name: "Administrator"},{id: 'client', name: "Cliente"}]} />
            <BooleanInput source="validated" label="Validated" />
            <BooleanInput source="blacklisted" label="Blacklisted" />
        </SimpleForm>
    </Create>
);
export const UserEdit = (props) => (
    <Edit title="Edit User" {...props}>
        <SimpleForm>
            <DisabledInput label="Id" source="id" />
            <TextInput source="email" />
            <SelectInput source="type" choices={[{id: 'admin', name: "Administrator"},{id: 'client', name: "Cliente"}]} />
            {/* <BooleanInput source="validated" label="Validated" /> */}
            {/* <BooleanInput source="blacklisted" label="Blacklisted" /> */}
        </SimpleForm>
    </Edit>
);