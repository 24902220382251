import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_CHECK } from 'react-admin';
import { config } from './config.js';
import decodeJwt from 'jwt-decode';

export default (type, params) => {
    console.log("type ", type)
    console.log("params ", params)
    if (type === AUTH_LOGIN) {
        console.log("params  :: ", params)
        const { username, password } = params;
        const request = new Request( config().api + '/auth', {
            method: 'POST',
            body: JSON.stringify({ email: username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        })
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ refresh_token }) => {
                const decodedToken = decodeJwt(refresh_token);
                console.log("token   ", refresh_token)
                localStorage.setItem('token', refresh_token);
            });
    }
    if (type === AUTH_LOGOUT) {
        console.log("AUTH_LOGOUT")
        localStorage.removeItem('token');
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        // ...
    }
    if (type === AUTH_CHECK) {
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    }
    // if (type === AUTH_CHECK) {
    //     console.log("AUTH_CHECK")
    //     return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    // }
    // if (type === AUTH_GET_PERMISSIONS) {
    //     const role = localStorage.getItem('role');
    //     return role ? Promise.resolve(role) : Promise.reject();
    // }
    return Promise.reject('Unknown method');
};