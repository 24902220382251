import React from 'react';
import {Filter, SimpleList, Responsive, List, Datagrid, DateField, ImageField, ChipField, BooleanField, TextField, Create, Edit, SimpleForm, ImageInput, ReferenceArrayInput, SelectArrayInput, TextInput, FileInput, BooleanInput, SelectInput, FileField } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { config } from '../config'
import { createConfigItem } from '@babel/core';
import { DateTimeInput } from 'react-admin-date-inputs';

const required = (message = 'Required') =>
    value => value ? undefined : message;

export const PostCreate = (props) => {

    return (<Create {...props}>
        <SimpleForm>
            <TextInput label="Title" source="title" validate={required()} />
            <RichTextInput label="Description" source="description" validate={required()} />
            <BooleanInput label="Active" source="active" />
            <BooleanInput label="Highlighted" source="highlighted" />
            <ImageInput source="images" multiple={true} label="Related pictures" accept="image/*" placeholder={<p>Drop your pictures here</p>}>
                <ImageField source="url" title="caption" />
            </ImageInput>
            <FileInput source="videos" multiple={true} label="Related videos" accept="video/*" placeholder={<p>Drop your videos here</p>}>
                <FileField source="url" title="caption" />
            </FileInput>
            <ReferenceArrayInput label="Themes" source='themes' reference="themes">
                <SelectArrayInput>
                    <ChipField source="name" />
                </SelectArrayInput>
            </ReferenceArrayInput>
        </SimpleForm>
    </Create>);
}

export const PostEdit = (props) => (
    <Edit title="Edit Post" {...props}>
        <SimpleForm>
            <TextInput label="Title" source="title" validate={required()} />
            <RichTextInput label="Description" source="description" validate={required()} />
            <BooleanInput label="Active" source="active" />
            <BooleanInput label="Highlighted" source="highlighted" />
            <ImageInput source="images" label="Related pictures" accept="image/*" multiple={true} placeholder={<p>Drop your pictures here</p>}>
                <ImageField source="url" title="caption" />
            </ImageInput>
            <FileInput source="videos" label="Related videos" accept="video/*" multiple={true} placeholder={<p>Drop your videos here</p>}>
                <FileField source="url" title="caption" />
            </FileInput>
            <ReferenceArrayInput label="Themes" source='themes' reference="themes">
                <SelectArrayInput>
                    <ChipField source="name" />
                </SelectArrayInput>
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);
export const PostList = (props) => (
    <List bulkActionButtons={false} filters={<InstanceFilter />} {...props}>
        <Responsive
            small={
                <SimpleList
                    primaryText={record => {
                        return record.title;
                    }}
                    secondaryText={record => new Date(record.updatedAt).toLocaleDateString()}
                />
            }
            medium={
                <Datagrid rowClick="show">
                    <DateField label="Created at" source="createdAt" />
                    <DateField label="Modified at" source="updatedAt" />
                    <TextField label="Title" source="title" />
                    <BooleanField label="Active" source="active" />
                    <BooleanField label="Highlighted" source="highlighted" />
                </Datagrid>
            }
        />
    </List>
);
const InstanceFilter = ({...props}) => (
    <Filter {...props}>
        <TextInput label="Title" source="title" alwaysOn />
        <DateTimeInput source="from_date" label="Created after" />
        <DateTimeInput source="to_date" label="Created before" />
    </Filter>
);