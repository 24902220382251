// in myRestProvider.js
import { stringify } from 'query-string';
import {
    GET_LIST,
    GET_ONE,
    CREATE,
    UPDATE,
    DELETE,
    GET_MANY,
    GET_MANY_REFERENCE,
    UPDATE_MANY,
    DELETE_MANY
} from 'react-admin';
import { config } from './config'

const apiUrl = config().api;
const baseUrl = config().url;

/**
 * Maps react-admin queries to my REST API
 *
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a data response
 */
export default (type, resource, params) => {
    let url = '';
    const options = {
        headers: new Headers({
            Accept: 'application/json',
        }),
    };
    const token = localStorage.getItem('token');
    if (token) {
        options.user = {
            authenticated: true,
            token: token
        }
    }
    options.headers.set('x-access-token', token);
    let query;
    switch (type) {
        case GET_LIST: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            console.log("params.filter ", params.filter, " resource ", resource)
            if(resource == "works" && params.filter.q !== undefined){
                params.filter.name = params.filter.q;
                delete params.filter.q;
            }
            query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([
                    (page - 1) * perPage,
                    page * perPage - 1,
                ]),
                filter: JSON.stringify(params.filter),
            };
            url = `${apiUrl}/${resource}?${stringify(query)}`;
            break;
        }
        case GET_ONE:
            url = `${apiUrl}/${resource}/${params.id}`;
            break;
        case CREATE:
            url = `${apiUrl}/${resource}`;
            options.method = 'POST';
            if (resource == 'works' || resource == 'posts' || resource == 'projects' || resource == 'headers' || resource == 'highlights' || resource == 'categories' || resource == 'comissions') {
                let form_data = new FormData();
                for (var key in params.data) {
                    if (key == 'work_zip') {
                        form_data.append(key, params.data[key].rawFile);
                    } else if (key == 'images' || key == 'videos'){
                        if (Array.isArray(params.data[key])) {
                            params.data[key].forEach(element => {
                                form_data.append(key, element.rawFile)
                            });
                        } else {
                            form_data.append(key, params.data[key].rawFile)
                        }
                    }else {
                        form_data.append(key, params.data[key]);
                    }
                }
                options.body = form_data;
            } else if (resource == 'awards'){
                let form_data = new FormData();
                for (var key in params.data) {
                    if (key == 'images_logo' || key == 'images_main') {
                        form_data.append(key, params.data[key].rawFile)
                    }else{
                        form_data.append(key, params.data[key]);
                    }
                }
                options.body = form_data;
            }else {
                options.headers.set('Content-Type', 'application/json');
                options.body = JSON.stringify(params.data);
            }
            break;
        case UPDATE:
            url = `${apiUrl}/${resource}/${params.id}`;
            options.method = 'PUT';
            if (resource == 'works' ) {
                params.data.categories = params.data.categories.toString();
            }
            if (resource == 'posts' || resource == 'projects' || resource == 'headers' || resource == 'highlights' || resource == 'categories' || resource == 'works' || resource == 'comissions'){
                let unchanged_Images = [];
                let unchanged_Videos = [];
                let form_data = new FormData();
                for (var key in params.data) {
                    if (key == 'images' || key == 'videos') {
                        if(Array.isArray(params.data[key])){
                            params.data[key].forEach(element => {
                                if(element.rawFile){
                                    form_data.append(key, element.rawFile)
                                }else{
                                    key === 'images' ? unchanged_Images.push(element.id) : unchanged_Videos.push(element.id)
                                }
                            });
                        }else {
                            form_data.append(key, params.data[key].rawFile)
                        }
                    } else {
                        form_data.append(key, params.data[key]);
                    }
                }
                if (unchanged_Videos.length) form_data.append('unchanged_Videos', unchanged_Videos)
                if (unchanged_Images.length) form_data.append('unchanged_Images', unchanged_Images)
                options.body = form_data;
            } else if (resource == 'comissions') {
                let form_data = new FormData();
                for (var key in params.data) {
                    if (key == 'logo_url') {
                        form_data.append('images', params.data[key].rawFile)
                    }else{
                        form_data.append(key, params.data[key]);
                    }
                }
                options.body = form_data;
            } else if (resource == 'awards') {
                let form_data = new FormData();
                for (var key in params.data) {
                    if (key == 'images_logo' || key == 'images_main' && (params.data[key] && params.data[key].rawFile)) {
                        if (params.data[key].rawFile) form_data.append(key, params.data[key].rawFile)
                    } else if(key == 'categories'){
                        if (params.data[key][0] !== undefined) form_data.append('category', params.data[key][0]);
                    } else if(key == 'categoryId'){
                        if (params.data[key] !== null && params.data[key] !== 'undefined') { 
                            form_data.append(key, params.data[key])
                        }
                    } else if(key == 'image_url' || key == 'logo_url'){
                        form_data.append(key, params.data[key].replace(baseUrl + "/", ""));
                    } else if (key !== 'category'){
                        form_data.append(key, params.data[key]);
                    } 
                }
                options.body = form_data;
            } else {
                options.headers.set('Content-Type', 'application/json');
                options.body = JSON.stringify(params.data);
            }
            break;
        case UPDATE_MANY:
            query = {
                filter: JSON.stringify({ id: params.ids }),
            };
            url = `${apiUrl}/${resource}?${stringify(query)}`;
            options.method = 'PATCH';
            options.body = JSON.stringify(params.data);
            break;
        case DELETE:
            url = `${apiUrl}/${resource}/${params.id}`;
            options.method = 'DELETE';
            break;
        case DELETE_MANY:
            query = {
                filter: JSON.stringify({ id: params.ids }),
            };
            url = `${apiUrl}/${resource}?${stringify(query)}`;
            options.method = 'DELETE';
            break;
        case GET_MANY: {
            query = {
                filter: JSON.stringify({ id: params.ids }),
            };
            url = `${apiUrl}/${resource}?${stringify(query)}`;
            break;
        }
        case GET_MANY_REFERENCE: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            query = {
                sort: JSON.stringify([field, order]),
                range: JSON.stringify([
                    (page - 1) * perPage,
                    page * perPage - 1,
                ]),
                filter: JSON.stringify({
                    ...params.filter,
                    [params.target]: params.id,
                }),
            };
            url = `${apiUrl}/${resource}?${stringify(query)}`;
            break;
        }
        default:
            throw new Error(`Unsupported Data Provider request type ${type}`);
    }
    let headers;
    return fetch(url, options)
        .then(res => {
            headers = res.headers;
            return res.json();
        })
        .then(json => {
            switch (type) {
                case GET_LIST:
                case GET_MANY_REFERENCE:
                    if (!headers.has('content-range')) {
                        throw new Error(
                            'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
                        );
                    }
                    return {
                        data: json,
                        total: parseInt(
                            headers
                                .get('content-range')
                                .split('/')
                                .pop(),
                            10
                        ),
                    };
                case CREATE:
                    return { data: { ...params.data, id: json.id } };
                case UPDATE:
                    if (resource === 'themes') {
                        return {
                            data: json[1]
                        };
                    }
                    return {
                        data: json
                    };
                case GET_ONE:
                    if (resource == 'works' || resource == 'projects' || resource == 'posts') {
                        let categories = [];
                        let themes = [];
                        if (json.categories) {
                            for (let cat = 0; cat < json.categories.length; cat++) {
                                categories.push(json.categories[cat].id);
                            }
                            json.categories = categories;
                        }
                        if (json.themes) {
                            for (let cat = 0; cat < json.themes.length; cat++) {
                                themes.push(json.themes[cat].id);
                            }
                            json.themes = themes;
                        }
                        
                        return {
                            data: json
                        };
                    } else if ( resource === 'themes' ) {
                        return {
                            data: json.rows[0]
                        };
                    } else if (resource == 'comissions') {
                        json.images = [{ url: json.logo_url}]
                        return {
                            data: json
                        };
                    } else if (resource == 'awards') {
                        if (json.category) {
                            json.categories = [json.category.id]
                        }
                        json.images_logo = [{ url: json.logo_url }]
                        if (json.image_url) json.images_main = [{ url: json.image_url }]
                        return {
                            data: json
                        };
                    } else {
                        return {
                            data: json
                        };
                    }
                default:
                    return { data: json };
            }
        });
};