import React from 'react';
import {Filter,TextInput, SimpleList, Responsive, List, Datagrid, TextField, EmailField, BooleanField } from 'react-admin';

export const UserList = (props) => (
    <List filters={<InstanceFilter />} {...props}>
        <Responsive
            small={
                <SimpleList
                    primaryText={record => { 
                        return record.email;
                    }}
                />
            }
            medium={
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <EmailField source="email" />
                <TextField source="type" />
                <BooleanField source="validated" />
                <BooleanField source="blacklisted" />
            </Datagrid>
            }
        />
    </List>
);

const InstanceFilter = ({...props}) => (
    <Filter {...props}>
        <TextInput label="Email" source="email" alwaysOn />
    </Filter>
);