import { Admin, Resource, fetchUtils, Login } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import authProvider from './authProvider';
import React, { Component }  from 'react';
import { UserList } from './user/UserList';
import { UserCreate, UserEdit } from './user/UserCreate';
import { WorkCreate, WorkEdit } from './work/WorkCreate';
import { CategoryCreate, CategoryEdit, CategoryList } from './categories/Category';
import { ThemeCreate, ThemeEdit, ThemeList } from './blog/Theme';
import { PostCreate, PostEdit, PostList } from './blog/Post';
import { ProjectCreate, ProjectEdit, ProjectList } from './project/Project';
import { HeaderCreate, HeaderEdit, HeaderList } from './header/Header';
import { HighlightsCreate, HighlightsEdit, HighlightsList } from './highlight/Highlight';
import { ComissionsCreate, ComissionsEdit, ComissionsList } from './comission/Comission';
import { AwardsCreate, AwardsEdit, AwardsList } from './award/Awards';
import { NewsletterList} from './newsletter/Newsletter';
import { WorkList } from './work/WorkList';
import ApiDataProvider from './ApiDataProvider';
import UserIcon from '@material-ui/icons/People';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import Forum from '@material-ui/icons/Forum';
import GroupWork from '@material-ui/icons/GroupWork';
import LocalOffer from '@material-ui/icons/LocalOffer';
import Mail from '@material-ui/icons/Mail';
import Style from '@material-ui/icons/Style';
import Panorama from '@material-ui/icons/Panorama';
import Highlight from '@material-ui/icons/Highlight';
import CardGiftcard from '@material-ui/icons/CardGiftcard';
import RecentActors from '@material-ui/icons/RecentActors';
import { config } from './config';
import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import indigo from '@material-ui/core/colors/indigo';
import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = localStorage.getItem('token');
    if(token){
            options.user = {
            authenticated: true,
            token: token
        }
    }
    options.headers.set('x-access-token', token);
    return fetchUtils.fetchJson(url, options);
}

const theme = createMuiTheme({
    palette: {
        primary: indigo,
    },
});

const dataProvider = simpleRestProvider(config.api, httpClient);
const MyLoginPage = () => <Login backgroundImage="https://lung.training/assets/four-steps-banner.jpg" />;

const App = () => (
    <Admin loginPage={MyLoginPage} title={<AppTitle />} dataProvider={ApiDataProvider} authProvider={authProvider}>
        <Resource icon={UserIcon} name="users" create={UserCreate} list={UserList} edit={UserEdit}/>
        <Resource icon={GroupWork} create={CategoryCreate}  edit={CategoryEdit} list={CategoryList} name="categories" />
        <Resource icon={Style} name="projects" create={ProjectCreate}  show={ProjectEdit} list={ProjectList}/>
        <Resource icon={LibraryBooks} name="works" create={WorkCreate}  show={WorkEdit} list={WorkList}/>
        <Resource options={{ label: 'Posts Themes' }} icon={LocalOffer} create={ThemeCreate}  edit={ThemeEdit} list={ThemeList} name="themes" />
        <Resource options={{ label: 'Blog Posts' }} icon={Forum} create={PostCreate}  edit={PostEdit} list={PostList} name="posts" />
        <Resource icon={Panorama} create={HeaderCreate}  edit={HeaderEdit} list={HeaderList} name="headers" />
        <Resource icon={Highlight} create={HighlightsCreate}  edit={HighlightsEdit} list={HighlightsList} name="highlights" />
        <Resource icon={RecentActors} create={ComissionsCreate}  edit={ComissionsEdit} list={ComissionsList} name="comissions" />
        <Resource icon={CardGiftcard} create={AwardsCreate} edit={AwardsEdit} list={AwardsList} name="awards" />
        <Resource icon={Mail} list={NewsletterList} name="newsletter" />
    </Admin>
);
// Default styles
const appTitleStyles = {
    whiteSpace        : 'nowrap',
    overflow          : 'hidden',
    WebkitTextOverflow: 'ellipsis',
    textOverflow      : 'ellipsis',
    margin            : 0,
    letterSpacing     : 0,
    fontSize          : 24,
    fontWeight        : '400',
    color             : 'rgb(255, 255, 255)',
    height            : 44,
    paddingTop        : 10,
    paddingBottom     : 10,
    WebkitFlex        : '1 1 0%',
    MsFlex            : '1 1 0%',
    flex              : '1 1 0%'
  };
const AppTitle = () => (
    <img style={appTitleStyles} src={config().url + "/files/logo.png"} />
  );

export default App;