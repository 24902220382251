import React from 'react';
import {Filter, SimpleList,AutocompleteInput,ReferenceInput, Responsive, List, Datagrid, DateField, ImageField, BooleanField, ChipField, TextField, Create, Edit, SimpleForm, ImageInput, ReferenceArrayInput, SelectArrayInput, TextInput, FileInput, BooleanInput, SelectInput, FileField } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { config } from '../config'
import { createConfigItem } from '@babel/core';
import { DateTimeInput } from 'react-admin-date-inputs';

const required = (message = 'Required') =>
    value => value ? undefined : message;

export const ProjectCreate = (props) => {

    return (<Create {...props}>
        <SimpleForm>
            <TextInput label="Name" source="name" validate={required()} />
            <RichTextInput label="Description" source="description" validate={required()} />
            <BooleanInput label="Highlighted" source="highlighted" />
            <BooleanInput label="Active" source="active" />
            <TextInput label="School" source="school" validate={required()} />
            <ImageInput source="images" multiple={true} label="Related pictures" accept="image/*" placeholder={<p>Drop your pictures here</p>}>
                <ImageField source="url" title="caption" />
            </ImageInput>
            <FileInput source="videos" multiple={true} label="Related videos" accept="video/*" placeholder={<p>Drop your videos here</p>}>
                <FileField source="url" title="caption" />
            </FileInput>
            <ReferenceArrayInput label="Categories" source='categories' reference="categories">
                <SelectArrayInput>
                    <ChipField source="name" />
                </SelectArrayInput>
            </ReferenceArrayInput>
            <ReferenceInput
                label="Import images from Work"
                source="work_id"
                reference="works"
            >
                <AutocompleteInput optionValue="id"/>
            </ReferenceInput>
            {/* <ReferenceInput label="Work" sort={{ field: 'name', order: 'ASC' }} source="work_id" reference="works" perPage={100} validate={required()}>
                   <AutocompleteInput optionText="name" optionValue="id" shouldRenderSuggestions={(val) => { return val.trim().length > 2 }} 
                       inputValueMatcher={(input, suggestion, getOptionText) => input.toUpperCase().trim() === suggestion.descricao || input.toLowerCase().trim() === getOptionText(suggestion).toLowerCase().trim()}
                   />  
               </ReferenceInput>  */}
        </SimpleForm>
    </Create>);
}

export const ProjectEdit = (props) => (
    <Edit title="Edit Project" {...props}>
        <SimpleForm>
            <TextInput label="Name" source="name" validate={required()} />
            <RichTextInput label="Description" source="description" validate={required()} />
            <BooleanInput label="Highlighted" source="highlighted" />
            <BooleanInput label="Active" source="active" />
            <TextInput label="School" source="school" validate={required()} />
            <ImageInput source="images" label="Related pictures" accept="image/*" multiple={true} placeholder={<p>Drop your pictures here</p>}>
                <ImageField source="url" title="caption" />
            </ImageInput>
            <FileInput source="videos" label="Related videos" accept="video/*" multiple={true} placeholder={<p>Drop your videos here</p>}>
                <FileField source="url" title="caption" />
            </FileInput>
            <ReferenceArrayInput label="Categories" source='categories' reference="categories">
                <SelectArrayInput>
                    <ChipField source="name" />
                </SelectArrayInput>
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);
export const ProjectList = (props) => (
    <List filters={<InstanceFilter />} bulkActionButtons={false} {...props}>
        <Responsive
            small={
                <SimpleList
                    primaryText={record => {
                        return record.name;
                    }}
                    secondaryText={record => new Date(record.updatedAt).toLocaleDateString()}
                />
            }
            medium={
                <Datagrid rowClick="show">
                    <DateField label="Created at" source="createdAt" />
                    <DateField label="Modified at" source="updatedAt" />
                    <TextField label="Name" source="name" />
                    <BooleanField label="Highlighted" source="highlighted" />
                    <BooleanField label="Active" source="active" />
                </Datagrid>
            }
        />
    </List>
);


const InstanceFilter = ({...props}) => (
    <Filter {...props}>
        <TextInput label="Name" source="name" alwaysOn />
        <BooleanInput label="Highlighted" source="highlighted" />
        <BooleanInput label="Active" source="active" />
        <DateTimeInput source="from_date" label="Created after" />
        <DateTimeInput source="to_date" label="Created before" />
    </Filter>
);