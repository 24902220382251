import React from 'react';
import {Filter, SimpleList, Responsive, List, Datagrid, DateField, BooleanField, UrlField, TextField, Create, Edit, SimpleForm, ChipField, ReferenceArrayInput, SelectArrayInput, TextInput, FileInput, BooleanInput, SelectInput, FileField } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { config } from '../config'
import { createConfigItem } from '@babel/core';

export const NewsletterList = (props) => (
    <List filters={<InstanceFilter />} bulkActionButtons={false} {...props}>
        <Responsive
            small={
                <SimpleList
                    primaryText={record => {
                        return record.email;
                    }}
                    secondaryText={record => record.state}
                    tertiaryText={record => new Date(record.updatedAt).toLocaleDateString()}
                />
            }
            medium={
                <Datagrid rowClick="show">
                    <DateField label="Created at" source="createdAt" />
                    <DateField label="Modified at" source="updatedAt" />
                    <TextField label="Email" source="email" />
                    <BooleanField label="Terms" source="terms" />
                    <TextField label="State" source="state" />
                </Datagrid>
            }
        />
    </List>
);
const InstanceFilter = ({...props}) => (
    <Filter {...props}>
        <TextInput label="Email" source="email" alwaysOn />
    </Filter>
);