import React from 'react';
import {Filter, SimpleList, Responsive, List, Datagrid, DateField, ImageField, BooleanField, TextField, Create, Edit, SimpleForm, ImageInput, ReferenceArrayInput, SelectArrayInput, TextInput, FileInput, BooleanInput, SelectInput, FileField } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { config } from '../config'
import { createConfigItem } from '@babel/core';
const required = (message = 'Required') =>
    value => value ? undefined : message;

export const HeaderCreate = (props) => {

    return (<Create {...props}>
        <SimpleForm>
            <TextInput label="Title" source="title" validate={required()} />
            <TextInput label="Slogan" source="slogan" />
            <RichTextInput label="Description" source="description" validate={required()} />
            <SelectInput source="area" choices={[{ id: 'home', name: "Home" }, { id: 'iniciative', name: "Iniciativa" }, { id: 'blog', name: "Blog" }, { id: 'apply', name: "Concorrer" }, { id: 'projects', name: "Projetos" }, { id: 'awards', name: "Prémios" }, { id: 'comissions', name: "Comissões" }]} validate={required()} />
            <TextInput label="Button text" source="button_text" />
            <TextInput label="Link url" source="link_url" />
            <BooleanInput label="Active" source="active" />
            <ImageInput source="images" label="Related pictures" accept="image/*" multiple={true} placeholder={<p>Drop your pictures here</p>}>
                <ImageField source="url" title="caption" />
            </ImageInput>
            <FileInput source="videos" label="Related videos" accept="video/*" multiple={true} placeholder={<p>Drop your videos here</p>}>
                <FileField source="url" title="caption" />
            </FileInput>
        </SimpleForm>
    </Create>);
}

export const HeaderEdit = (props) => (
    <Edit title="Edit Header" {...props}>
        <SimpleForm>
            <TextInput label="Title" source="title" validate={required()} />
            <TextInput label="Slogan" source="slogan" />
            <RichTextInput label="Description" source="description" validate={required()} />
            <SelectInput source="area" choices={[{ id: 'home', name: "Home" }, { id: 'iniciative', name: "Iniciativa" }, { id: 'projects', name: "Projetos" }]} validate={required()} />
            <TextInput label="Button text" source="button_text" />
            <TextInput label="Link url" source="link_url" />
            <BooleanInput label="Active" source="active" />
            <ImageInput source="images" label="Related pictures" accept="image/*" multiple={true} placeholder={<p>Drop your pictures here</p>}>
                <ImageField source="url" title="caption" />
            </ImageInput>
            <FileInput source="videos" label="Related videos" accept="video/*" multiple={true} placeholder={<p>Drop your videos here</p>}>
                <FileField source="url" title="caption" />
            </FileInput>
        </SimpleForm>
    </Edit>
);
export const HeaderList = (props) => (
    <List filters={<InstanceFilter />}  bulkActionButtons={false} {...props}>
        <Responsive
            small={
                <SimpleList
                    primaryText={record => {
                        return record.title;
                    }}
                    secondaryText={record => record.area}
                    tertiaryText={record => new Date(record.updatedAt).toLocaleDateString()}
                />
            }
            medium={
                <Datagrid rowClick="show">
                    <DateField label="Created at" source="createdAt" />
                    <DateField label="Modified at" source="updatedAt" />
                    <TextField label="Title" source="title" />
                    <TextField label="Area" source="area" />
                </Datagrid>
            }
        />
    </List>
);

const InstanceFilter = ({...props}) => (
    <Filter {...props}>
        <TextInput label="Title" source="title" alwaysOn />
        <TextInput label="Area" source="area" alwaysOn />
    </Filter>
);