import React from 'react';
import { ChipField, NumberInput, Filter, SimpleList, Responsive, List, Datagrid, DateField, ImageField, BooleanField, TextField, Create, Edit, SimpleForm, ImageInput, ReferenceArrayInput, SelectArrayInput, TextInput, FileInput, BooleanInput, SelectInput, FileField } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { config } from '../config'
import { createConfigItem } from '@babel/core';
const required = (message = 'Required') =>
    value => value ? undefined : message;

export const AwardsCreate = (props) => {
    return (<Create {...props}>
        <SimpleForm>
            <TextInput label="Title" source="title" validate={required()} />
            <RichTextInput label="Description" source="description" validate={required()} />
            <TextInput label="Link url" source="link_url" />
            <BooleanInput label="Active" source="active" />
            <SelectInput source="area" choices={[{ id: 'partners', name: "Partners" }, { id: 'awards', name: "Awards" }]} validate={required()} />
            <ImageInput source="images_logo" label="Logo" accept="image/*" multiple={false} placeholder={<p>Drop your pictures here</p>} validate={required()}>
                <ImageField source="url" title="caption" />
            </ImageInput>
            <ImageInput source="images_main" label="Main Picture" accept="image/*" multiple={false} placeholder={<p>Drop your pictures here</p>}>
                <ImageField source="url" title="caption" />
            </ImageInput>
            <ReferenceArrayInput label="Categories" source='category' reference="categories">
                <SelectArrayInput>
                    <ChipField source="name" />
                </SelectArrayInput>
            </ReferenceArrayInput>
            <NumberInput label="Order" source="item_order" />
        </SimpleForm>
    </Create>);
}

export const AwardsEdit = (props) => (
    <Edit title="Edit Awards" {...props}>
        <SimpleForm>
            <TextInput label="Title" source="title" validate={required()} />
            <RichTextInput label="Description" source="description" validate={required()} />
            <TextInput label="Link url" source="link_url" />
            <BooleanInput label="Active" source="active" />
            <SelectInput source="area" choices={[{ id: 'partners', name: "Partners" }, { id: 'awards', name: "Awards" }]} validate={required()} />
            <ImageInput source="images_logo" label="Logo" accept="image/*" multiple={false} placeholder={<p>Drop your pictures here</p>} validate={required()}>
                <ImageField source="url" title="caption" />
            </ImageInput>
            <ImageInput source="images_main" label="Main Picture" accept="image/*" multiple={false} placeholder={<p>Drop your pictures here</p>}>
                <ImageField source="url" title="caption" />
            </ImageInput>
            <ReferenceArrayInput label="Categories" source='categories' reference="categories">
                <SelectArrayInput>
                    <ChipField source="name" />
                </SelectArrayInput>
            </ReferenceArrayInput>
            <NumberInput label="Order" source="item_order" />
        </SimpleForm>
    </Edit>
);

export const AwardsList = (props) => (
    <List filters={<InstanceFilter />} bulkActionButtons={false} {...props}>
        <Responsive
            small={
                <SimpleList
                    primaryText={record => {
                        return record.title;
                    }}
                    secondaryText={record => record.area}
                    tertiaryText={record => new Date(record.updatedAt).toLocaleDateString()}
                />
            }
            medium={
                <Datagrid rowClick="show">
                    <DateField label="Created at" source="createdAt" />
                    <DateField label="Modified at" source="updatedAt" />
                    <TextField label="Title" source="title" />
                    <BooleanField label={'Active'} source="active" />
                </Datagrid>
            }
        />
    </List>
);
const InstanceFilter = ({ ...props }) => (
    <Filter {...props}>
        <TextInput label="Title" source="title" alwaysOn />
    </Filter>
);