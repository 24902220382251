import React from 'react';
import { Filter, Responsive, SimpleList, List, Datagrid, DateField, DisabledInput, UrlField, TextField, Create, Edit, SimpleForm, ChipField, ReferenceArrayInput, SelectArrayInput, TextInput, ImageInput, ImageField, SelectInput, FileField} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { config } from '../config'
import { createConfigItem } from '@babel/core';
const required = (message = 'Required') =>
    value => value ? undefined : message;

export const CategoryCreate = (props) => {
    
    return(<Create {...props}>
        <SimpleForm>
            <TextInput label="Name" source="name" validate={required()} />
            <ImageInput source="images" label="Related picture" accept="image/*" multiple={false} placeholder={<p>Drop your picture here</p>} validate={required()}>
                <ImageField source="url" title="caption" />
            </ImageInput>
           
            {/* <ReferenceArrayInput label = "Categories" source='categories' reference="categories">
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput> */}
        </SimpleForm>
    </Create>);
}

export const CategoryEdit = (props) => (
    <Edit title="Edit Category" {...props}>
        <SimpleForm>
            <TextInput label="Name" source="name" validate={required()} />
            <DisabledInput label="Created at" source="createdAt" /> 
            <DisabledInput label="Modified at" source="updatedAt" /> 
            <ImageInput source="images" label="Related picture" accept="image/*" multiple={false} placeholder={<p>Drop your picture here</p>} validate={required()}>
                <ImageField source="url" title="caption" />
            </ImageInput>
        </SimpleForm>
    </Edit>
);
export const CategoryList = (props) => (
    <List filters={<InstanceFilter />} bulkActionButtons={false} {...props}>
        <Responsive
            small={
                <SimpleList
                    primaryText={record => { 
                        return record.name;
                    }}
                    secondaryText={record => new Date(record.updatedAt).toLocaleDateString()}
                />
            }
            medium={
                <Datagrid rowClick="show">
                    <TextField label="Name" source="name" />
                    <DateField label="Created at" source="createdAt" /> 
                    <DateField label="Modified at" source="updatedAt" /> 
                </Datagrid>
            }
        />
    </List>
);
const InstanceFilter = ({...props}) => (
    <Filter {...props}>
        <TextInput label="Search" source="name" alwaysOn />
    </Filter>
);