import React from 'react';
import {Filter, SimpleList, Responsive, ReferenceInput, List, Datagrid, DateField, DisabledInput, UrlField, TextField, Create, Edit, SimpleForm,ChipField, ReferenceArrayInput, SelectArrayInput, TextInput, FileInput, BooleanInput, SelectInput, FileField} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { config } from '../config'
import { createConfigItem } from '@babel/core';
import { ColorField, ColorInput } from 'react-admin-color-input';
const required = (message = 'Required') =>
    value => value ? undefined : message;

export const ThemeCreate = (props) => {
    
    return(<Create {...props}>
        <SimpleForm>
            <TextInput label="Name" source="name" validate={required()} />
            <ColorInput source="color" />
            {/* <ReferenceArrayInput label = "Categories" source='categories' reference="categories">
                <SelectArrayInput optionText="name" />
            </ReferenceArrayInput> */}
        </SimpleForm>
    </Create>);
}

export const ThemeEdit = (props) => (
    <Edit title="Edit Theme" {...props}>
        <SimpleForm>
            <TextInput label="Name" source="name" validate={required()} />
            <DisabledInput label="Created at" source="createdAt" /> 
            <DisabledInput label="Modified at" source="updatedAt" /> 
            <ColorInput source="color" />

            {/* <ReferenceArrayInput label = "Works" source='works' reference="works">
                <SelectArrayInput>
                    <ChipField source="name" />
                </SelectArrayInput>
            </ReferenceArrayInput>
            <Href label={'Work zip'} source="work_zip_url" /> */}
        </SimpleForm>
    </Edit>
);
export const ThemeList = (props) => (
   <List title={'Blog post themes list'} filters={<InstanceFilter />} bulkActionButtons={false} {...props} >
        <Responsive
            small={
                <SimpleList
                    primaryText={record => { 
                        return record.name;
                    }}
                    secondaryText={record => record.color}
                    tertiaryText={record => new Date(record.updatedAt).toLocaleDateString()}
                />
            }
            medium={
                <Datagrid rowClick="show">
                    <DateField label="Created at" source="createdAt" /> 
                    <DateField label="Modified at" source="updatedAt" /> 
                    <TextField label="Name" source="name" />
                    <ColorField source="color" />
                </Datagrid>
            }
        />
   </List>
);

const InstanceFilter = ({...props}) => (
    <Filter {...props}>
        <TextInput label="Search" source="name" alwaysOn />
        <TextInput label="Color" source="color" defaultValue="Search by color!" />
    </Filter>
);
